define('m12/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var skalierungsfaktor = x3d.get('skalierungsfaktor');

      var item = x3d.get('bauteile').findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;
      var abstandVonDerMasslinie = 0.05;

      if (name === 'auflager' || name === 'traeger' || name === 'auflagernaheEinzellast') {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (name === 'traeger') {
          if (variable === 'breite') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = -(x / 2) - hoeheMasskette - abstandVonDerMasslinie + ' ' + bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10 + ' ' + 0;
            } else {
              cords = -x / 2 + ' ' + (bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10 + hoeheMasskette + abstandVonDerMasslinie) + ' ' + 0;
            }
          } else if (variable === 'hoehe') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = -(x / 2) + ' ' + y / 2 + ' ' + (-z / 2 - hoeheMasskette - abstandVonDerMasslinie);
            } else {
              cords = -(x / 2) - hoeheMasskette - abstandVonDerMasslinie + ' ' + y / 2 + ' ' + -(z / 2);
            }
          } else if (variable === 'sicherheitsAbstand') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + (y - Number(x3d.get('sicherheitsAbstand')) / 20) + ' ' + (z / 2 + hoeheMasskette + abstandVonDerMasslinie);
            } else {
              cords = x / 2 + hoeheMasskette + abstandVonDerMasslinie + ' ' + (y - Number(x3d.get('sicherheitsAbstand')) / 20) + ' ' + z / 2;
            }
          }
        }

        if (name === 'auflager') {
          z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 10;
          if (variable === 'breite') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              var ueberstand = 0;

              if (bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === true) {
                ueberstand = Number(item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;
              } else {
                x = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10;
              }

              cords = ueberstand + x / 2 + hoeheMasskette + abstandVonDerMasslinie + ' ' + 0 + ' ' + 0;
            } else {
              cords = x / 2 + ' ' + (-hoeheMasskette + abstandVonDerMasslinie) + ' ' + 0;
            }
          } else if (variable === 'laenge') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = 0 + ' ' + 0 + ' ' + (z / 2 + hoeheMasskette - abstandVonDerMasslinie);
            } else {
              cords = 0 + ' ' + (-hoeheMasskette + abstandVonDerMasslinie) + ' ' + z / 2;
            }
          } else if (variable === 'ueberstand') {
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10 + bauteile.findBy('id', name).get('erweiterteeigenschaften').objectAt(0).get('ueberstand') / 10;
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + 0 + ' ' + (z / 2 + hoeheMasskette - abstandVonDerMasslinie);
            } else {
              cords = x / 2 + ' ' + (-hoeheMasskette + abstandVonDerMasslinie) + ' ' + z / 2;
            }
          }
        }

        if (name === 'auflagernaheEinzellast') {
          if (variable === 'breite') {
            z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10 + bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + 0 + ' ' + (-z - hoeheMasskette - abstandVonDerMasslinie);
            } else {
              cords = x / 2 + ' ' + (+hoeheMasskette + abstandVonDerMasslinie) + ' ' + -z;
            }
          }
        }
      }

      // if (name.substring(0, 8) === 'schraube'){
      //
      //   var xKopfPos = this.getSchraubeKopfPos(name, 'x');
      //   var zKopfPos = this.getSchraubeKopfPos(name, 'z');
      //
      //   if (this.get('typ') === 'xPos'){
      //     if (x3d.get('istHt')){
      //       z = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')/20;
      //     }else{
      //       z = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')/20;
      //     }
      //
      //     if (this.austrittspunktRechts){
      //       cords = ((bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')/10)+ 1 + this.positionDurchSkalierung(0)-0.25) + ' '+ (-xKopfPos/2) + ' ' + (-z-0.3);
      //     }else{
      //       if(bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')){
      //         cords = (xKopfPos/2) + ' '+ (this.positionDurchSkalierung(0)-0.25) + ' ' + (-z-0.3);
      //       }else{
      //         cords = (xKopfPos/2) + ' 0 ' + ((-z)-this.positionDurchSkalierung(0));
      //       }
      //     }
      //   }
      //
      //   if (this.get('typ') === 'zPos'){
      //
      //     if((bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) && (!x3d.get('istHt'))){
      //       x = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')/10 + 0.5;
      //     }else if((bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind'))){
      //       x = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')/10 + 0.3;
      //     }else{
      //       x = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x')/10;
      //     }
      //
      //     var delta = 0;
      //     var zPos = 0;
      //     var bezugspunkt = 0;
      //
      //     switch(this.get('variable')) {
      //         case 'randAbstand1':
      //               bezugspunkt = -x3d.get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('z')/20;
      //               zPos = bezugspunkt + x3d.get('randAbstand1')/20;
      //               delta = 0.6;
      //             break;
      //         case 'randAbstand2':
      //               bezugspunkt = x3d.get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('z')/20;
      //               zPos = bezugspunkt - x3d.get('randAbstand2')/20;
      //             break;
      //         case 'schraubenAbstand1':
      //               bezugspunkt = -x3d.get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('z')/20;
      //               zPos = bezugspunkt + x3d.get('schraubenAbstand1')/20 + x3d.get('randAbstand1')/10;
      //               delta = 0.6;
      //             break;
      //         case 'schraubenAbstand2':
      //               bezugspunkt = x3d.get('bauteile').findBy('id', 'auflager').get('boxsizes').objectAt(0).get('z')/20;
      //               zPos = bezugspunkt - x3d.get('schraubenAbstand2')/20 - x3d.get('randAbstand2')/10;
      //             break;
      //     }
      //
      //     if(bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')){
      //       cords = (x) + ' ' + (this.positionDurchSkalierung(0)+delta-0.35) + ' ' + (zPos);
      //     }else{
      //       cords = ((x+delta)+this.positionDurchSkalierung(0)) + ' ' + y + ' ' + (zPos);
      //     }
      //   }
      // }

      if (name === 'lastenPfeil') {
        cords = 0 + ' ' + -1.2 + ' 0';
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenlisteAktualisiert'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');
      var values;

      var w90Grad = 89 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      if (name === 'traeger') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 -1 -1 ' + w240Grad;
          } else {
            values = '0 1 0 ' + -w90Grad;
          }
        } else if (variable === 'hoehe') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 1 -1 ' + w240Grad;
          } else {
            values = '0 0 1 ' + w90Grad;
          }
        } else if (variable === 'sicherheitsAbstand') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '-1 1 1 ' + w240Grad;
          } else {
            values = '0 0 1 ' + -w90Grad;
          }
        }
      }

      if (name === 'auflager') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 1 1 ' + w240Grad;
          } else {
            values = '0 1 0 ' + -w90Grad;
          }
        } else if (variable === 'laenge') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 0 0 0';
          }
        } else if (variable === 'ueberstand') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 0 0 0';
          }
        }
      }

      if (name === 'auflagernaheEinzellast') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 1 0 ' + 0;
          }
        }
      }

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var variable = this.get('variable');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      if (name === 'auflager') {
        if (variable === 'laenge' && x3d.get('aLaengeEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('x');
        } else if (variable === 'breite' && x3d.get('aBreiteEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('z');
        } else if (variable === 'ueberstand' && Number(item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) !== 0 && x3d.get('ueberstandEingetragen')) {
          value = item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand');
        }
      }

      if (name === 'traeger') {
        if (variable === 'hoehe' && x3d.get('htHoeheEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('y');
        } else if (variable === 'breite' && x3d.get('htBreiteEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('z');
        } else if (variable === 'sicherheitsAbstand' && x3d.get('sicherheitsAbstandEingetragen')) {
          value = x3d.get('sicherheitsAbstand');
        }
      }

      if (name === 'auflagernaheEinzellast') {
        if (variable === 'breite' && x3d.get('abstandsmassEingetragen')) {
          value = item.get('translations').objectAt(0).get('x');
        }
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('variable')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (name === 'lastenPfeil') {
        value = x3d.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('variable');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'traeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'traeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abreiteAktiv') === true && name === 'auflager' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('alaengeAktiv') === true && name === 'auflager' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hWinkelAktiv') === true && name === 'hWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('sicherheitsAbstandAktiv') === true && name === 'traeger') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.abreiteAktiv', 'model.firstObject.alaengeAktiv', 'model.firstObject.sicherheitsAbstandAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.bemessungslastAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    }

  });

  exports['default'] = x3dText;

});