define('m12/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var x,
          y,
          z = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('translations').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;
      } else {
        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('length') / 10;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;
      }

      var values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var drehwinckel = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z');
      var values = '';

      values = '0 0 1 ' + Math.PI;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});