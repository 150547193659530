define('m12/controllers/optionen', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    _min_n0: "",
    _min_n90: "",
    _min_a1cPlatte: "",
    _min_a2cPlatte: "",
    _sicherheitsAbstand: "",

    openFromFile: false,

    ttmin_n0: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttmin_n90: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttmin_a1cPlatte: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttmin_a2cPlatte: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    ttsicherheitsAbstand: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      _min_n0: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999
        }
      },
      _min_n90: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999
        }
      },
      _min_a1cPlatte: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      _min_a2cPlatte: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      _sicherheitsAbstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    init: function init() {
      this._super();

      this.set('_min_n0', "1");
      this.set('_min_n90', "1");
      this.set('_min_a1cPlatte', "0.0");
      this.set('_min_a2cPlatte', "0.0");
      this.set('_sicherheitsAbstand', "0.0");
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      this.set('openFromFile', true);

      this.set('_min_n0', values.min_n0);
      this.setX3D(parseInt(values.min_n0), { target: { name: "_min_n0" } });

      this.set('_min_n90', values.min_n90);
      this.setX3D(parseInt(values.min_n90), { target: { name: "_min_n90" } });

      this.set('_min_a1cPlatte', values.min_a1cPlatte);
      this.setX3D(Number(values.min_a1cPlatte), { target: { name: "_min_a1cPlatte" } });

      this.set('_min_a2cPlatte', values.min_a2cPlatte);
      this.setX3D(Number(values.min_a2cPlatte), { target: { name: "_min_a2cPlatte" } });

      this.set('_sicherheitsAbstand', values.sicherheitsAbstand);
      this.setX3D(Number(values.sicherheitsAbstand), { target: { name: "_sicherheitsAbstand" } });

      this.send('validation');

      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('_min_a1cPlatte').indexOf(",") !== -1) {
          self.set('_min_a1cPlatte', self.get('_min_a1cPlatte').replace(",", "."));
        }

        if (self.get('_min_a2cPlatte').indexOf(",") !== -1) {
          self.set('_min_a2cPlatte', self.get('_min_a2cPlatte').replace(",", "."));
        }

        if (self.get('_sicherheitsAbstand').indexOf(",") !== -1) {
          self.set('_sicherheitsAbstand', self.get('_sicherheitsAbstand').replace(",", "."));
        }
      }
    }).observes('_min_a1cPlatte', '_min_a2cPlatte', '_sicherheitsAbstand'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      var optionenData = application.get('model').optionen.objectAt(0);

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case '_min_n0':
          if (this.errors._min_n0.length === 0) {
            x3d.set('min_n0', value);
            optionenData.set('min_n0', value);
            application.get('model').x3ddefault.objectAt(0).set('_min_n0Eingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('_min_n0Eingetragen', false);
          }
          break;
        case '_min_n90':
          if (this.errors._min_n90.length === 0) {
            x3d.set('min_n90', value);
            optionenData.set('min_n90', value);
            application.get('model').x3ddefault.objectAt(0).set('min_n90Eingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('min_n90Eingetragen', false);
          }
          break;
        case '_min_a1cPlatte':
          if (this.errors._min_a1cPlatte.length === 0) {
            x3d.set('min_a1cPlatte', value);
            optionenData.set('min_a1cPlatte', value);
            application.get('model').x3ddefault.objectAt(0).set('min_a1cPlatteEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('min_a1cPlatteEingetragen', false);
          }
          break;
        case '_min_a2cPlatte':
          if (this.errors._min_a2cPlatte.length === 0) {
            x3d.set('min_a2cPlatte', value);
            optionenData.set('min_a2cPlatte', value);
            application.get('model').x3ddefault.objectAt(0).set('min_a2cPlatteEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('min_a2cPlatteEingetragen', false);
          }
          break;
        case '_sicherheitsAbstand':
          if (this.errors._sicherheitsAbstand.length === 0) {
            x3d.set('sicherheitsAbstand', value);
            optionenData.set('sicherheitsAbstand', value);
            application.get('model').x3ddefault.objectAt(0).set('sicherheitsAbstandEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('sicherheitsAbstandEingetragen', false);
          }

          this.setSchraubenlaenge();
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('optionenInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);
          } else {
            self.set('displayErrors', true);
            application.set('optionenInvalid', true);
          }
        });
      },

      _min_n0IsSelected: function _min_n0IsSelected() {
        var min_n0Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('min_n0Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('min_n0Aktiv', !min_n0Aktiv);

        if (min_n0Aktiv === false) {
          document.getElementsByName('_min_n0')[0].setSelectionRange(0, this.get('_min_n0').length);
        }
      },

      _min_n90IsSelected: function _min_n90IsSelected() {
        var min_n90Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('min_n90Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('min_n90Aktiv', !min_n90Aktiv);

        if (min_n90Aktiv === false) {
          document.getElementsByName('_min_n90')[0].setSelectionRange(0, this.get('_min_n90').length);
        }
      },

      _min_a1cPlatteIsSelected: function _min_a1cPlatteIsSelected() {
        var min_a1cPlatteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('min_a1cPlatteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('min_a1cPlatteAktiv', !min_a1cPlatteAktiv);

        if (this.get('_min_a1cPlatte') !== "") {
          var work = parseFloat(this.get('_min_a1cPlatte').replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('_min_a1cPlatte', rounded);
          this.send('validation', rounded, { target: { name: "_min_a1cPlatte" } });
        }

        if (min_a1cPlatteAktiv === false) {
          document.getElementsByName('_min_a1cPlatte')[0].setSelectionRange(0, this.get('_min_a1cPlatte').length);
        }
      },

      _min_a2cPlatteIsSelected: function _min_a2cPlatteIsSelected() {
        var min_a2cPlatteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('min_a2cPlatteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('min_a2cPlatteAktiv', !min_a2cPlatteAktiv);

        if (this.get('_min_a2cPlatte') !== "") {
          var work = parseFloat(this.get('_min_a2cPlatte').replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('_min_a2cPlatte', rounded);
          this.send('validation', rounded, { target: { name: "_min_a2cPlatte" } });
        }

        if (min_a2cPlatteAktiv === false) {
          document.getElementsByName('_min_a2cPlatte')[0].setSelectionRange(0, this.get('_min_a2cPlatte').length);
        }
      },

      _sicherheitsAbstandIsSelected: function _sicherheitsAbstandIsSelected() {
        var sicherheitsAbstandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('sicherheitsAbstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('sicherheitsAbstandAktiv', !sicherheitsAbstandAktiv);

        if (this.get('_sicherheitsAbstand') !== "") {
          var work = parseFloat(this.get('_sicherheitsAbstand').replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('_sicherheitsAbstand', rounded);
          this.send('validation', rounded, { target: { name: "_sicherheitsAbstand" } });
        }

        if (sicherheitsAbstandAktiv === false) {
          document.getElementsByName('_sicherheitsAbstand')[0].setSelectionRange(0, this.get('_sicherheitsAbstand').length);
        }
      }

    }, // Ende actions

    setGrenzwert: function setGrenzwert(feldname, grenzwertMax) {
      var self = this;

      self.set('validations._' + feldname + '.numericality.lessThanOrEqualTo', Number(grenzwertMax));
      self.set('tt' + feldname, self.get('i18n').t('wertebereich') + " 0 - " + grenzwertMax + " [cm]");
    },

    setSchraubenlaenge: function setSchraubenlaenge() {
      var self = this;

      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');
      var propertiesSchraubeStandard1 = bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0);
      var traegerhoehe = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y');

      propertiesSchraubeStandard1.set('length', traegerhoehe - Number(x3d.get('sicherheitsAbstand')));
      propertiesSchraubeStandard1.set('threadlengthtip', traegerhoehe - Number(x3d.get('sicherheitsAbstand')));
    }
  });

});