define('m12/templates/tex-t', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("shape");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("appearance");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("material");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("text");
        dom.setAttribute(el2,"solid","false");
        dom.setAttribute(el2,"lit","false");
        dom.setAttribute(el2,"ccw","true");
        dom.setAttribute(el2,"usegeocache","true");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("fontstyle");
        dom.setAttribute(el3,"family","Helvetica");
        dom.setAttribute(el3,"horizontal","true");
        dom.setAttribute(el3,"justify","middle");
        dom.setAttribute(el3,"lefttoright","true");
        dom.setAttribute(el3,"spacing","1.0");
        dom.setAttribute(el3,"toptobottom","true");
        dom.setAttribute(el3,"quality","10.0");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [1]);
        var attrMorph0 = dom.createAttrMorph(element1, 'diffusecolor');
        var attrMorph1 = dom.createAttrMorph(element2, 'string');
        var attrMorph2 = dom.createAttrMorph(element3, 'size');
        attribute(env, attrMorph0, element1, "diffusecolor", get(env, context, "diffusecolor"));
        attribute(env, attrMorph1, element2, "string", get(env, context, "wert"));
        attribute(env, attrMorph2, element3, "size", get(env, context, "textgroesse"));
        return fragment;
      }
    };
  }()));

});