define('m12/controllers/traeger', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 10 - 999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 999.9 [cm]";
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 10 - 999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 999.9 [cm]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    ntmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }, {
      fklind: 8,
      fkl: "C35"
    }, {
      fklind: 9,
      fkl: "C40"
    }, {
      fklind: 10,
      fkl: "C45"
    }, {
      fklind: 11,
      fkl: "C50"
    }],

    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 24,
      fkl: "GL30h"
    }, {
      fklind: 23,
      fkl: "GL32h"
    }],

    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 34,
      fkl: "GL30c"
    }, {
      fklind: 33,
      fkl: "GL32c"
    }],

    htfklarray: [],
    ntfklarray: [],

    init: function init() {
      this._super();
      // this.set('ntfklarray', this.fkl_vh);
      this.setSelectFieldsContent();

      this.set('ntholz');
      this.set('fkl');

      this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = { target: { name: "htbreite" } };
      var hthoeheEvent = { target: { name: "hthoehe" } };

      this.set('openFromFile', true);

      this.set('htbreite', values.TimberElement_b);
      this.setX3D(parseFloat(values.TimberElement_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElement_h);
      this.setX3D(parseFloat(values.TimberElement_h), hthoeheEvent);

      this.set('ntholz', parseInt(values.MatKeyTimberElement));
      this.set('ntfkl', parseInt(values.FKLKeyTimberElement));

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setMaterialien();
      self.setFKL();
    },

    setMaterialien: function setMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setFKL: function setFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ntfkl', 30);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ntfkl', 5);
      }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;

      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe'),

    watchNtMaterial: (function () {
      // if (this.get('ntholz') === 1) {
      //   this.set('ntfklarray', this.fkl_bsh);
      //   this.set('ntfkl', 20);
      // } else if (this.get('ntholz') === 13) {
      //   this.set('ntfklarray', this.fkl_bsh_kombi);
      //   this.set('ntfkl', 30);
      // } else {
      //   this.set('ntfklarray', this.fkl_vh);
      //   this.set('ntfkl', 5);
      // }

      this.setFKL();
    }).observes('ntholz'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('z', value);
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);

            var auflager = self.controllerFor('auflager');
            auflager.set('validations.abreite.numericality.lessThanOrEqualTo', parseFloat(self.get('htbreite')));
            //unter umstäaeden tritt hier ein fehler auf!!!
            auflager.set('ttBreiteA', self.get('i18n').t('wertebereich') + " 0.1 - " + self.get('htbreite') + " [cm]");
            if (value < Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('z'))) {
              bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).set('z', value);
            }
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('y', value);

            if (bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager')) {
              bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 1.5 * value);
            } else {
              bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 3 * value);
            }

            self.controllerFor('optionen').setSchraubenlaenge();
            self.controllerFor('optionen').setGrenzwert("sicherheitsAbstand", value);

            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var traeger = this.controllerFor('application').get('model').traeger.objectAt(0);

      traeger.set('MatKeyTimberElement', this.get('ntholz'));
      traeger.set('FKLKeyTimberElement', this.get('ntfkl'));

      self.controllerFor('lasteinwirkung').setnklarray();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('vorbelastung'), { target: { name: "vorbelastung" } });
    }).observes('ntholz', 'ntfkl'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('traegerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);
            var traeger = self.controllerFor('application').get('model').traeger.objectAt(0);

            traeger.set('TimberElement_b', parseFloat(self.get('htbreite')).toFixed(1));
            traeger.set('TimberElement_h', parseFloat(self.get('hthoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('traegerInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheaktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      }
    }
  });

});