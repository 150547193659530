define('m12/models/bauteil', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    typ: DS['default'].attr('string'),
    boxsizes: DS['default'].hasMany('boxsize', { async: false }),
    appearances: DS['default'].hasMany('appearance', { async: false }),
    translations: DS['default'].hasMany('translation', { async: false }),
    rotations: DS['default'].hasMany('rotation', { async: false }),
    diffusecolors: DS['default'].hasMany('diffusecolor', { async: false }),
    orientations: DS['default'].hasMany('orientation', { async: false }),
    positions: DS['default'].hasMany('position', { async: false }),
    screwProperties: DS['default'].hasMany('screwProperty', { async: false }),
    cameraProperties: DS['default'].hasMany('cameraProperty', { async: false }),
    erweiterteeigenschaften: DS['default'].hasMany('erweiterteeigenschaft', { async: false }),

    richtungsvektor: DS['default'].hasMany('richtungsvektor', { async: false })

  });

});