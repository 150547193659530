define('m12/models/erweiterteeigenschaft', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    ueberstand: DS['default'].attr('number'),
    abstandsmass: DS['default'].attr('number'),
    istendauflager: DS['default'].attr('boolean'),
    iststreckenlast: DS['default'].attr('boolean'),
    auflagernaheeinzellast: DS['default'].attr('boolean')

  });

});