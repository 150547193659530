define('m12/controllers/supercontroller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    testAusgabe: function testAusgabe(text) {},

    uebergabedatenErzeugen: function uebergabedatenErzeugen() {

      var self = this;
      var application = self.controllerFor('application');

      //var applicationdata = application.model.objectAt(0);
      var applicationdata = application.get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);

      var projektdatendata = application.get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);

      var traegerdata = application.get('model').traeger.objectAt(0);
      var traegerdataJSON = JSON.stringify(traegerdata);

      var auflagerdata = application.get('model').auflager.objectAt(0);
      var auflagerdataJSON = JSON.stringify(auflagerdata);

      var optionendata = application.get('model').optionen.objectAt(0);
      var optionendataJSON = JSON.stringify(optionendata);

      var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("traegerdataJSON: " + traegerdataJSON);
        console.log("auflagerdataJSON: " + auflagerdataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      }

      var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(traegerdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(optionendataJSON), JSON.parse(lasteinwirkungdataJSON));

      var timberelement = {
        "Querschnittswerte": {
          "QuerschnittsID": "1",
          "t_Fastener": traegerdata.get('TimberElement_b'),
          "b": traegerdata.get('TimberElement_b'),
          "h": traegerdata.get('TimberElement_h')
        },
        "vorgebohrt": traegerdata.get('TimberElement_Predrilled'),
        "alphaRAD_KraftFaser": "0.0",
        "alphaRAD_vmFaser": traegerdata.get('alphaRAD_vmFaser'),
        "betaRAD_vmFurnier": "90.0",
        "MatKey": traegerdata.get('MatKeyTimberElement'),
        "FKLKey": traegerdata.get('FKLKeyTimberElement')

      };

      var berechnungsoptionen = {
        "min_n0": Number(optionendata.get('min_n0')),
        "min_n90": Number(optionendata.get('min_n90')),
        "min_a1cPlatte": Number(optionendata.get('min_a1cPlatte')),
        "min_a2cPlatte": Number(optionendata.get('min_a2cPlatte')),
        "sicherheitsAbstand": Number(optionendata.get('sicherheitsAbstand'))
      };

      uebergabedaten.timberelem = timberelement;
      uebergabedaten.berechnungsoptionen = berechnungsoptionen;

      return uebergabedaten;
    },

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {

      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {}

      console.log(" ");
      console.log("***** logaufruf von *****");
      console.log(_aufruf);

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m12").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten));
      // form.append("", "{\"datum\":12345,\"bid\":\"00000000-0000-0000-0000-000000000000\",\"request\":0,\"firma\":1,\"modul\":0,\"bemessung\":0,\"bemessungNAD\":0,\"lastannahmen\":0,\"lastannahmenNAD\":0,\"eingabeSprache\":0,\"ausgabeSprache\":0,\"katalog\":0,\"methode\":0,\"userIp\":null,\"url\":null,\"status\":0,\"artikelnummer\":4711}");

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;
      // var url = "http://assy-bemessung.online/webapi/speichereAktion";
      // var url = "https://onlinebemessung.software/webapi/speichereAktion";

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            console.log("statusText: " + statusText + " ::: status: " + xhr.status); // xhr.status --> 200
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M12.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true, true);
    }

  });

});