define('m12/components/materia-l', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Material = Ember['default'].Component.extend({
    tagName: 'material',
    attributeBindings: ['diffusecolor', 'transparency', 'ambientintensity', 'emissivecolor', 'shininess', 'specularcolor'],
    ambientintensity: "0.2",
    emissivecolor: "0,0,0",
    shininess: "0.2",
    specularcolor: "0,0,0",

    diffusecolor: (function () {

      var name = this.get('name');
      var m06 = this.get('model');
      var bauteile = m06.objectAt(0).get('bauteile');
      var values;

      var item = bauteile.findBy('id', name);

      values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.m06.bauteile'),

    transparency: (function () {

      var name = this.get('name');
      var m06 = this.get('model').objectAt(0);
      var bauteile = m06.get('bauteile');
      var value;

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === 'box' && !m06.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if (item.get('typ') === 'box' && m06.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    }).property('model.m06.bauteile', 'model.firstObject.istGitterModell')
  });

  exports['default'] = Material;

});