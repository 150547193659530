define('m12/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    schraubenListe: (function () {
      var self = this;

      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var count = 0;
      var schrListe = Ember['default'].Set.create();

      bauteile.forEach(function (item) {

        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          count = count + 1;
          schrListe.add(item);

          // if (x3d.get('ergebnisGeladen') === true){
          //   var rVektor = item.get('richtungsvektor').objectAt(0);
          //
          //   // var yWinkel = self.getWinkel({x: rVektor.get('x'), y: 0, z: rVektor.get('z')}, {x: 0, y: 0, z:1});
          //   var zWinkel = self.getWinkel({x: rVektor.get('x'), y: rVektor.get('y'), z: rVektor.get('z')}, {x: 0, y: 1, z:0});
          //
          //   // x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('y', yWinkel);
          //   x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('z', zWinkel);
          // }
        }
      });

      if (this.get('model').objectAt(0).get('ergebnisGeladen')) {
        this.setAbstaende(schrListe);
      }

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    setAbstaende: function setAbstaende(schrliste) {
      var x3d = this.get('model').objectAt(0);

      var tBreite = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z');

      var liste1 = [];
      var liste2 = [];

      var randAbstand1 = 0,
          randAbstand2 = 0,
          schraubenAbstand1 = 0,
          schraubenAbstand2 = 0;

      schrliste.forEach(function (item) {

        var xVerschiebung = item.get('translations').objectAt(0).get('x');
        var yVerschiebung = item.get('translations').objectAt(0).get('y');
        var zVerschiebung = item.get('translations').objectAt(0).get('z');

        var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length');
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
          alpha = 1.5707 - (alpha - 1.5707);
          xVerschiebung = xVerschiebung * -1;
        }

        var xKopfPos = Math.cos(beta) * (Math.cos(alpha) * schraubenlaenge) + xVerschiebung;
        var zKopfPos = zVerschiebung + Math.sin(beta) * (yVerschiebung / Math.tan(alpha));

        if (xKopfPos > 0) {
          liste2.push(zKopfPos);
        } else {
          liste1.push(zKopfPos);
        }
      });

      liste1.sort(function (a, b) {
        return a - b;
      });
      liste2.sort(function (a, b) {
        return b - a;
      });

      // randAbstand1 = -tBreite/2 - liste1[0];
      // randAbstand2 = tBreite/2 - liste2[0];
      //
      // x3d.set('randAbstand1', Math.abs(randAbstand1));
      // x3d.set('randAbstand2', Math.abs(randAbstand2));

      if (liste1.length > 1) {
        schraubenAbstand1 = Math.abs(liste1[0] - liste1[1]);
      }
      if (liste2.length > 1) {
        schraubenAbstand2 = liste2[0] - liste2[1];
      }

      // x3d.set('schraubenAbstand1', schraubenAbstand1);
      // x3d.set('schraubenAbstand2', schraubenAbstand2);
    },

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },
    schraubenabstaende1: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand1', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende2: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand2', 'model.firstObject.schraubenlisteAktualisiert'),

    gekreuzteSchrauben: (function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenGekreuzt')

  });

  exports['default'] = Schrauben;

});