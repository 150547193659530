define('m12/routes/optionen', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),
    hn: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('haupt_nebentraeger');
    }),

    model: function model() {
      return this.store.find('optionen');
    },

    setupController: function setupController() {
      // console.log('***** Betrete Route Optionen *****');
      this.controllerFor('application').miniertX3D();
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('routeOptionenAktiv', true);
    },

    actions: {
      willTransition: function willTransition(transition) {
        // console.log('##### Verlasse Route Optionen #####');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('routeOptionenAktiv', false);
      }
    }

  });

});