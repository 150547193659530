define('m12/models/optionen', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var optionen = DS['default'].Model.extend({

		min_n0: DS['default'].attr('number'),
		min_n90: DS['default'].attr('number'),
		min_a1cPlatte: DS['default'].attr('number'),
		min_a2cPlatte: DS['default'].attr('number'),
		sicherheitsAbstand: DS['default'].attr('number')

	});

	optionen.reopenClass({
		FIXTURES: [{
			id: 1,
			min_n0: 1.0,
			min_n90: 1.0,
			min_a1cPlatte: 0,
			min_a2cPlatte: 0,
			sicherheitsAbstand: 0
		}]
	});

	exports['default'] = optionen;

});