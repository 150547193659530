define('m12/controllers/impressum', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    wuerth: false, //don't change this!
    swg: true, //don't change this!

    selectedLanguage: "DE",

    init: function init() {

      this._super();

      // var application = this.controllerFor('application');
      // this.set('wuerth', application.get('wuerth'));
      // this.set('swg', application.get('swg'));
    },

    spracheDeutsch: (function () {
      var self = this;

      if (self.get("selectedLanguage") === "DE") {
        return true;
      } else {
        return false;
      }
    }).property('selectedLanguage')

  });

});