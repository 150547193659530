define('m12/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    min_n0: DS['default'].attr('number', { defaultValue: 1 }),
    min_n90: DS['default'].attr('number', { defaultValue: 1 }),
    min_a1cPlatte: DS['default'].attr('number', { defaultValue: 0 }),
    min_a2cPlatte: DS['default'].attr('number', { defaultValue: 0 }),
    sicherheitsAbstand: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    alaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ueberstandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandsmassAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    min_n0Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    min_n90Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    min_a1cPlatteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    min_a2cPlatteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    sicherheitsAbstandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    routeOptionenAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    aBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    aLaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    ueberstandEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    abstandsmassEingetragen: DS['default'].attr('boolean', { defaultValue: true }),
    min_n0Eingetragen: DS['default'].attr('boolean', { defaultValue: true }),
    min_n90Eingetragen: DS['default'].attr('boolean', { defaultValue: true }),
    min_a1cPlatteEingetragen: DS['default'].attr('boolean', { defaultValue: true }),
    min_a2cPlatteEingetragen: DS['default'].attr('boolean', { defaultValue: true }),
    sicherheitsAbstandEingetragen: DS['default'].attr('boolean', { defaultValue: true }),

    bemessungslastEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 1;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 15 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 15 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    randAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    randAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand2: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});