define('m12/controllers/auflager', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    aTyp: 1,
    istZwischenlager: false,
    displayErrors: true,
    abreite: "",
    alaenge: "",
    ueberstand: "0.0",
    abstandsmass: "0.0",
    streckenlast: false,
    auflagernaheeinzellast: false,
    abstandsmasstoggle: true,
    openFromFile: false,

    ttBreiteA: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.1 - 999.0 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttLaengeA: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.1 - 999.0 [cm]";
    }),
    ttUeberstand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),
    ttAbstandsmass: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.0 [cm]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      abreite: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      alaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      ueberstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      abstandsmass: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    aTypen: [],

    auflagerTypen: [{
      name: "endauflager",
      id: 1
    }, {
      name: "zwischenauflager",
      id: 0
    }],

    init: function init() {
      this._super();
      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var abreiteEvent = { target: { name: "abreite" } };
      var alaengeEvent = { target: { name: "alaenge" } };
      var ueberstandEvent = { target: { name: "ueberstand" } };
      var abstandsmassEvent = { target: { name: "abstandsmass" } };

      this.set('openFromFile', true);

      this.set('abreite', values.bLager);
      this.setX3D(parseFloat(values.bLager), abreiteEvent);

      this.set('alaenge', values.lLager);
      this.setX3D(parseFloat(values.lLager), alaengeEvent);

      this.set('aTyp', parseInt(values.LagerTyp));

      this.set('abstandsmass', "");
      this.set('abstandsmass', values.L1);
      this.setX3D(parseInt(values.L1), abstandsmassEvent);

      this.set('streckenlast', values.Streckenlast);
      this.set('auflagernaheeinzellast', values.Einzellast);

      this.set('ueberstand', "");
      this.set('ueberstand', values.dl_Lager);
      this.setX3D(parseFloat(values.dl_Lager), ueberstandEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setAuflagerTypen();
    },

    setAuflagerTypen: function setAuflagerTypen() {
      var self = this;
      var indices = [1, 0];
      self.set('aTypen', self.getSelectFieldContent('auflagerTypen', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }
      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'auflagerTypen':
          dbContent = this.get('auflagerTypen');
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('abreite').indexOf(",") !== -1) {
          self.set('abreite', self.get('abreite').replace(",", "."));
        }

        if (self.get('alaenge').indexOf(",") !== -1) {
          self.set('alaenge', self.get('alaenge').replace(",", "."));
        }

        if (self.get('ueberstand').indexOf(",") !== -1) {
          self.set('ueberstand', self.get('ueberstand').replace(",", "."));
        }

        if (self.get('abstandsmass').indexOf(",") !== -1) {
          self.set('abstandsmass', self.get('abstandsmass').replace(",", "."));
        }
      }
    }).observes('abreite', 'alaenge', 'ueberstand', 'abstandsmass'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var auflager = this.controllerFor('application').get('model').auflager.objectAt(0);

      auflager.set('LagerTyp', this.get('aTyp'));
      auflager.set('LagerText', this.get('aTyp'));

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var traegerhoehe = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y');

      if (this.get('aTyp') === 1) {
        x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('istendauflager', true);
        x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 1.5 * traegerhoehe);
        self.set('istZwischenlager', false);
      } else if (this.get('aTyp') === 0) {
        x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('istendauflager', false);
        x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 3 * traegerhoehe);
        self.set('istZwischenlager', true);
      }

      self.setTraegerLaenge(x3d.get('bauteile'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    }).observes('aTyp'),

    watchCheckboxes: (function () {

      var self = this;

      var auflager = this.controllerFor('application').get('model').auflager.objectAt(0);
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var application = self.controllerFor('application');

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      application.set('verbindungsmittelInvalid', true);

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      auflager.set('Streckenlast', this.get('streckenlast'));
      auflager.set('Einzellast', this.get('auflagernaheeinzellast'));

      if (self.get('auflagernaheeinzellast')) {
        self.set('abstandsmasstoggle', false);
        auflager.set('L1', parseFloat(self.get('abstandsmass')).toFixed(1));
      } else {
        self.set('abstandsmasstoggle', true);
        auflager.set('L1', "0.0");
      }

      x3d.get('bauteile').findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('auflagernaheeinzellast', self.get('auflagernaheeinzellast'));
      self.setTraegerLaenge(x3d.get('bauteile'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('streckenlast', 'auflagernaheeinzellast'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var m06 = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      console.log('SET X3D');

      switch (event.target.name) {
        case 'abreite':
          if (this.errors.abreite.length === 0) {
            bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).set('z', value);
            self.controllerFor('optionen').setGrenzwert("min_a2cPlatte", value / 2);
            application.get('model').x3ddefault.objectAt(0).set('aBreiteEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('aBreiteEingetragen', false);
          }
          break;
        case 'alaenge':
          if (this.errors.alaenge.length === 0) {
            bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).set('x', value);
            this.setTraegerLaenge(bauteile);
            self.controllerFor('optionen').setGrenzwert("min_a1cPlatte", value / 2);
            application.get('model').x3ddefault.objectAt(0).set('aLaengeEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('aLaengeEingetragen', false);
          }
          break;
        case 'ueberstand':
          if (this.errors.ueberstand.length === 0) {
            bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).set('ueberstand', value);
            this.setTraegerLaenge(bauteile);
            application.get('model').x3ddefault.objectAt(0).set('ueberstandEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ueberstandEingetragen', false);
          }
          break;
        case 'abstandsmass':
          if (this.errors.abstandsmass.length === 0) {
            bauteile.findBy('id', 'auflagernaheEinzellast').get('translations').objectAt(0).set('x', value);
            this.setTraegerLaenge(bauteile);
            application.get('model').x3ddefault.objectAt(0).set('abstandsmassEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('abstandsmassEingetragen', false);
          }
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    setTraegerLaenge: function setTraegerLaenge(bauteile) {

      var self = this;

      var auflagernaheEinzellast = 0;

      if (self.get('auflagernaheeinzellast')) {
        auflagernaheEinzellast = Number(bauteile.findBy('id', 'auflagernaheEinzellast').get('translations').objectAt(0).get('x'));
      }

      console.log('auflagernaheEinzellast: ' + auflagernaheEinzellast);

      var traegerlaenge = 2 * (Number(bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) + Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x')) + auflagernaheEinzellast);

      console.log('traegerlaenge: ' + traegerlaenge);

      bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', traegerlaenge);
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('auflagerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var auflager = self.controllerFor('application').get('model').auflager.objectAt(0);

            auflager.set('bLager', parseFloat(self.get('abreite')).toFixed(1));
            auflager.set('lLager', parseFloat(self.get('alaenge')).toFixed(1));
            auflager.set('dl_Lager', parseFloat(self.get('ueberstand')).toFixed(1));
            if (self.get('auflagernaheeinzellast')) {
              auflager.set('L1', parseFloat(self.get('abstandsmass')).toFixed(1));
            } else {
              auflager.set('L1', '0.0');
            }
          } else {
            self.set('displayErrors', true);
            application.set('auflagerInvalid', true);
          }
        });
      },

      abreiteIsSelected: function abreiteIsSelected() {
        var abreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abreiteAktiv', !abreiteaktiv);

        if (this.get('abreite') !== "") {
          var abreitework = parseFloat(this.get('abreite').replace(",", "."));
          var abreiterounded = abreitework.toFixed(1);
          this.set('abreite', abreiterounded);
          this.send('validation', abreiterounded, { target: { name: "abreite" } });
        }

        if (abreiteaktiv === false) {
          document.getElementsByName('abreite')[0].setSelectionRange(0, this.get('abreite').length);
        }
      },

      alaengeIsSelected: function alaengeIsSelected() {
        var alaengeaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('alaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('alaengeAktiv', !alaengeaktiv);

        if (this.get('alaenge') !== "") {
          var alaengework = parseFloat(this.get('alaenge').replace(",", "."));
          var alaengerounded = alaengework.toFixed(1);
          this.set('alaenge', alaengerounded);
          this.send('validation', alaengerounded, { target: { name: "alaenge" } });
        }

        if (alaengeaktiv === false) {
          document.getElementsByName('alaenge')[0].setSelectionRange(0, this.get('alaenge').length);
        }
      },

      ueberstandIsSelected: function ueberstandIsSelected() {
        var ueberstandaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uberstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueberstandAktiv', !ueberstandaktiv);

        if (this.get('ueberstand') !== "") {
          var ueberstandwork = parseFloat(this.get('ueberstand').replace(",", "."));
          var ueberstandrounded = ueberstandwork.toFixed(1);
          this.set('ueberstand', ueberstandrounded);
          this.send('validation', ueberstandrounded, { target: { name: "ueberstand" } });
        }

        if (ueberstandaktiv === false) {
          document.getElementsByName('ueberstand')[0].setSelectionRange(0, this.get('ueberstand').length);
        }
      },

      abstandsmassIsSelected: function abstandsmassIsSelected() {
        var abstandsmassaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandsmassAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandsmass', !abstandsmassaktiv);

        if (this.get('abstandsmass') !== "") {
          var abstandsmasswork = parseFloat(this.get('abstandsmass').replace(",", "."));
          var abstandsmassrounded = abstandsmasswork.toFixed(1);
          this.set('abstandsmass', abstandsmassrounded);
          this.send('validation', abstandsmassrounded, { target: { name: "abstandsmass" } });
        }

        if (abstandsmassaktiv === false) {
          document.getElementsByName('abstandsmass')[0].setSelectionRange(0, this.get('abstandsmass').length);
        }
      }
    }
  });

});